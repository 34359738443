body {
  font-family: 'Average', serif;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  background: #f3f6f8;
  scroll-behavior: smooth;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
.slider-custom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade__in__animation {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.carousel .slide {
  background: none !important;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.talk_animation {
  border-radius: 10px;
  width: 7px;
  height: 15px;
  background-color: #01b07c;
  animation: talk 0.5s infinite alternate;
}

.kep-login-facebook.metro {
  width: 280px;
  font-size: 14px;
  border-radius: 30px !important;
  height: 38px;
  padding: 0;
  text-transform: none;
}

@keyframes talk {
  0% {
    transform: scaleY(2);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(2);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #ecf1f4;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: lightgray;
}

::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

::-webkit-scrollbar-thumb:active {
  background: lightgray;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(20deg);
  }
  95% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wiggle {
  display: inline-block;
  animation: wiggle 2.5s;
}

.wiggle:hover {
  animation: none;
}

.display_mobile {
  display: none;
}

@media (max-width: 600px) {
  .dont_display_mobile {
    display: none;
  }

  .display_mobile {
    display: flex;
  }
}

.animation__grow {
  transition: all 0.2s ease-in-out;
}
.animation__grow:hover {
  transform: scale(1.05);
}

.input-range__track--active {
  background: #650675 !important;
  height: 3px !important;
}

.input-range__track--background {
  height: 3px !important;
  background: rgba(79, 167, 180, 0.2) !important;
}

.input-range__slider {
  background: #650675 !important;
  border: #650675 !important;
  width: 14px !important;
  height: 14px !important;
  margin-top: -9px !important;
}

.input-range__label {
  font-size: 12px !important;
}

@media (max-width: 600px) {
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.puls {
  position: relative;
}

.puls span {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  background: inherit;
  border-radius: inherit;
  animation-delay: calc(1s * var(--i));
}

@keyframes pulsAnimation {
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes wiggleV2 {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(10deg);
  }
  45% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

.shake {
  animation: shake 0.5s;
}

.wiggleV2 {
  animation: wiggleV2 1s ease-in-out;
}
