.container * {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #05275d;
}

.container {
  text-align: center;
  width: 320px;
  margin: 20px auto 10px;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
}

.card-container {
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  padding: 10px;
  text-align: left;
  box-sizing: border-box;
}

.card-container aside {
  padding-bottom: 6px;
}

.payu-card-form {
  background-color: #ffffff;
  color: #05275d;
  padding: 10px 5px;
  border: 1px solid #05275d;
  border-radius: 15px;
}

.card-details {
  overflow: auto;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.card-details .expiration {
  width: 100px;
}

.card-details .cvv {
  width: 100px;
}

button {
  display: inline-flex;
  padding: 17px 73px 14px 72px;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  background: #650675;

  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: none;
}

button :hover {
  outline: none;
  border: none;
  transform: scale(1.1);
}

.response-success {
  color: #438f29;
}

.response-error {
  color: #990000;
}
